export function openFirstTab() {
    for (const item of document.querySelectorAll('.nav-pills li:first-of-type a')) {
        item.setAttribute('aria-selected', true)
        item.classList.add('active', 'show')
    }

    for (const item of document.querySelectorAll('.tab-content .tab-pane:first-of-type')) {
        item.classList.add('active', 'show')
    }
}
