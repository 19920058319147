function optionToSlug(text) {
    return text.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, ' ')
        .toLowerCase()
        .replace(/[^0-9a-z- ]+/g, '')
        .trim()
        .replace(/ /g, '-')
}

export function setCatalogByHash(selectbox) {
    if (!selectbox) {
        return
    }
    let hashUrl = new URL(document.URL).hash.substring(1)
    if (hashUrl) {
        try {
            hashUrl = decodeURI(hashUrl)
        } catch(e) {
            console.error(e)
        }
        const hashSlug = optionToSlug(hashUrl)
        for (let i = 0; i < selectbox.options.length; i++) {
            if (optionToSlug(selectbox.options[i].text) === hashSlug) {
                selectbox.value = selectbox.options[i].value
                selectbox.dispatchEvent(new Event('change'))
                break
            }
        }
    }
    selectbox.addEventListener('change', () => {
        window.location.hash = optionToSlug(selectbox.options[selectbox.selectedIndex].text)
    })
}
