export function collapseAddNameToItems() {
    for (const elem of document.querySelectorAll(".collapse-box div[id^='trigger'] a")) {
        if (!elem.hasAttribute('name')) {
            elem.setAttribute('name', elem.getAttribute('href').substring(1))
            elem.addEventListener('click', (event) => {
                event.preventDefault()
                history.pushState ?
                    history.pushState(null, null, event.target.getAttribute('href')) :
                    location.hash = event.target.getAttribute('href')
            })
        }
    }
}

export function collapseOpenItemWhenFragment() {
    if (window.location.hash) {
        const node = document.querySelector(".collapse-box div[id^='trigger'] a[href='" + window.location.hash + "']")
        if (node) {
            node.click()
        }
    }
}
