import { clickToClass } from './code'
import { showNavigationForMobile } from './navigation'
import { searchBar } from './search'
import { openFirstTab } from './tab'
import { collapseAddNameToItems, collapseOpenItemWhenFragment } from './collapse'
import { EmailForm } from './nia-email'
import { NiakampfTerms } from './niakampf-terms'
import { setCatalogByHash } from '../../../plugins/mojeid_matalog/static/mojeid_matalog/js/catalog_by_hash'

window.addEventListener('DOMContentLoaded', () => {
    clickToClass("show-nav", showNavigationForMobile)
    searchBar()
    openFirstTab()
    collapseAddNameToItems()
    collapseOpenItemWhenFragment()

    setTimeout(() => {
        // Selectbox id_category is driven by Vuejs, so wait until it is created.
        setCatalogByHash(document.getElementById("id_category"))
    }, 600)

    for (const form of document.querySelectorAll(`.nia-form`)) {
        new EmailForm(form)
    }

    for (const form of document.querySelectorAll('.niakampf-order')) {
        new NiakampfTerms(form)
    }
}, false)
