/*
    Search
*/

import { setToClass } from './code'

export function searchBar() {
    const searchBox = document.querySelector('.search-box')

    document.querySelectorAll('.search-icon, .search-close, .search-box .bg, .mobile-search').forEach((item) => {
        item.addEventListener('click', () => {
            setToClass('search-box', function(element) {element.classList.toggle("active")})

            searchBox.classList.contains('active')
                ? searchBox.querySelector('input[type=text]').focus()
                : searchBox.querySelector('input[type=text]').blur()
        })
    })
}
