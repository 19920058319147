export class NiakampfTerms {
    constructor(form) {
        this.form = form
        this.checkbox = form.querySelector('input[name="confirm"]')

        this.checkbox.parentNode.insertAdjacentHTML('afterend', this.createHTML())
        this.alertMsg = form.querySelector('.alert.alert-danger')

        this.form.addEventListener('submit', (e) => {
            if (!this.checkbox.checked) {
                e.preventDefault()
                this.alertMsg.classList.remove('d-none')
            }
        })

        this.checkbox.addEventListener('click', () => {
            !this.alertMsg.classList.contains('d-none')
                ? this.alertMsg.classList.add('d-none')
                : false
        })
    }

    createHTML() {
        return `<div class="alert alert-danger d-none">Je třeba souhlasit s podmínkami kampaně</div>`
    }
}