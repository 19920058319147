export class EmailForm {
    constructor(form) {
        this.form = form
        this.checkInput = true
        this.btnSubmit = form.querySelector('button')

        for (const elem of form.querySelectorAll('.email-box')) {
            if (elem.querySelector('input').hasAttribute('disabled')) {
                elem.querySelector('label').textContent = 'E-mail odeslán'
            } else {
                this.checkInput = false
            }
        }

        this.toggleBtn()
    }

    toggleBtn() {
        this.checkInput
            ? this.btnSubmit.setAttribute('disabled', true)
            : this.btnSubmit.removeAttribute('disabled')
    }
}
