export function setToClass(class_name, funct) {
    for (const elem of document.getElementsByClassName(class_name)) {
        funct(elem)
    }
}

export function addEventToClass(event_name, class_name, funct) {
    setToClass(class_name, function(elem) {elem.addEventListener(event_name, funct)})
}

export function clickToClass(class_name, funct) {
    addEventToClass('click', class_name, funct)
}
